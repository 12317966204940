import React, { useState } from 'react';
import {
  Container, Col, Row, Media, CarouselControl
} from 'reactstrap';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Lightbox from 'react-image-lightbox';

import { Layout } from '../Components';
import defaultImage from '../assets/images/default-image.png';
import calendar from '../assets/images/calendar.png';

const Post = (props) => {
  const { data } = props;
  const [displayImage, setDisplayImage] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const { wordpressPost } = data;
  const {
    acf: { images: gallery }
  } = wordpressPost;

  return (
    <Layout>
      <Container>
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <Media middle>
              {gallery && gallery.length > 0 ? (
                <>
                  <Media
                    middle
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <Img
                      fluid={
                        gallery[displayImage].full_image_url.localFile
                          .childImageSharp.fluid
                      }
                      className="mx-auto d-block img-fluid"
                    />
                    <CarouselControl
                      direction="prev"
                      directionText=""
                      onClickHandler={() => {
                        setDisplayImage(
                          (displayImage + gallery.length - 1) % gallery.length
                        );
                      }}
                    />
                    <CarouselControl
                      direction="next"
                      directionText=""
                      onClickHandler={() => {
                        setDisplayImage(
                          (displayImage + gallery.length + 1) % gallery.length
                        );
                      }}
                    />
                  </Media>
                  <Row className="justify-content-center">
                    {gallery.map((el, index) => (
                      <a
                        href="#gallery"
                        key={el.full_image_url.localFile.publicURL}
                        className=""
                      >
                        <Media
                          className="img-fluid galleryPreview mx-auto my-1 galleryPreviewSelect"
                          style={{
                            backgroundImage: `url(${el.full_image_url.localFile.publicURL})`,
                            backgroundSize: 'cover'
                          }}
                          onClick={() => {
                            setDisplayImage(index);
                          }}
                        />
                      </a>
                    ))}
                  </Row>
                  {isOpen && (
                    <Lightbox
                      mainSrc={
                        gallery[displayImage].full_image_url.localFile.publicURL
                      }
                      nextSrc={
                        gallery[(displayImage + 1) % gallery.length]
                          .full_image_url.localFile.publicURL
                      }
                      prevSrc={
                        gallery[
                          (displayImage + gallery.length - 1) % gallery.length
                        ].full_image_url.localFile.publicURL
                      }
                      onCloseRequest={() => setOpen(false)}
                      onMovePrevRequest={() => {
                        setDisplayImage(
                          (displayImage + gallery.length - 1) % gallery.length
                        );
                      }}
                      onMoveNextRequest={() => {
                        setDisplayImage(
                          (displayImage + gallery.length + 1) % gallery.length
                        );
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  {wordpressPost.featured_media !== null ? (
                    <Img
                      fixed={
                        wordpressPost.featured_media.localFile.childImageSharp
                          .fixed
                      }
                      style={{ maxHeight: '400px', width: '100%' }}
                    />
                  ) : (
                    <img
                      src={defaultImage}
                      className="mx-auto d-block"
                      alt={wordpressPost.title}
                      style={{ maxHeight: '400px' }}
                    />
                  )}
                </>
              )}
            </Media>
          </Col>
          <Col lg={{ size: 8, offset: 2 }}>
            <h3
              className="my-3"
              dangerouslySetInnerHTML={{ __html: wordpressPost.title }}
            />
            <span>
              <img
                src={calendar}
                className="Calendar__icon"
                alt={wordpressPost.date}
              />
              {wordpressPost.date}
            </span>
            <p
              dangerouslySetInnerHTML={{ __html: wordpressPost.content }}
              className="Post__content"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Post;
export const query = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      content
      title
      slug
      date(formatString: "D/MM/YYYY")
      featured_media {
        localFile {
          childImageSharp {
            fixed(width: 960, height: 540, quality: 100) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
      acf {
        images {
          full_image_url {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 960, maxHeight: 600, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
